// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.*/
        <img src="https://qbitihome.com/imagenes/qbitihome/logo.png" alt="100" width="100" />
        /* <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 182.582 134.03">
            <g id="Grupo_21" data-name="Grupo 21" transform="translate(-243.23 -250.632)">
                <g id="Grupo_16" data-name="Grupo 16" transform="translate(386.422 290.85)">
                    <path
                        id="Trazado_43"
                        data-name="Trazado 43"
                        d="M474.11,312.685a24.521,24.521,0,0,0-34.684,0,2.885,2.885,0,1,0,4.081,4.078,18.753,18.753,0,0,1,26.521,0,2.885,2.885,0,1,0,4.081-4.078Z"
                        transform="translate(-438.582 -305.5)"
                        fill="#0041d3"
                    />
                    <path
                        id="Trazado_44"
                        data-name="Trazado 44"
                        d="M459.369,319.277a14.336,14.336,0,0,0-10.2,4.225,2.885,2.885,0,0,0,4.081,4.081,8.66,8.66,0,0,1,12.243,0,2.885,2.885,0,0,0,4.081-4.081A14.334,14.334,0,0,0,459.369,319.277Z"
                        transform="translate(-441.182 -309.178)"
                        fill="#0041d3"
                    />
                </g>
                <path
                    id="Trazado_45"
                    data-name="Trazado 45"
                    d="M263.907,377.241a3.268,3.268,0,0,0-3.324,3.552v8.789a.242.242,0,0,1-.273.273H250.2a.242.242,0,0,1-.273-.273v-8.789a3.354,3.354,0,1,0-6.7,0V405.2a3.245,3.245,0,0,0,3.325,3.553,3.3,3.3,0,0,0,3.37-3.553V396.1a.242.242,0,0,1,.273-.273H260.31a.242.242,0,0,1,.273.273V405.2a3.353,3.353,0,1,0,6.694,0V380.793A3.278,3.278,0,0,0,263.907,377.241Z"
                    transform="translate(0 -33.805)"
                    fill="#0041d3"
                />
                <path
                    id="Trazado_46"
                    data-name="Trazado 46"
                    d="M292.731,389.108c-4.827,0-8.243,2.277-9.563,6.33a18.847,18.847,0,0,0,0,10.384c1.32,4.053,4.737,6.331,9.563,6.331s8.244-2.277,9.565-6.331a18.864,18.864,0,0,0,0-10.384C300.975,391.385,297.559,389.108,292.731,389.108Zm3.416,15.212a3.6,3.6,0,0,1-6.832,0,16.784,16.784,0,0,1,0-7.378,3.6,3.6,0,0,1,6.832,0,16.811,16.811,0,0,1,0,7.378Z"
                    transform="translate(-10.469 -36.973)"
                    fill="#0041d3"
                />
                <path
                    id="Trazado_47"
                    data-name="Trazado 47"
                    d="M341.911,389.108a8.758,8.758,0,0,0-6.923,2.869h-.045a6.781,6.781,0,0,0-5.966-2.869,7.077,7.077,0,0,0-5.83,2.55H323.1a3,3,0,0,0-3.052-2.323,3.087,3.087,0,0,0-3.188,3.37v15.85a3.193,3.193,0,1,0,6.376,0V398.536c0-2.414,1.138-4.008,3.416-4.008s3.461,1.594,3.461,4.008v10.019a3.193,3.193,0,1,0,6.376,0V398.536c0-2.414,1.184-4.008,3.416-4.008,2.277,0,3.461,1.594,3.461,4.008v10.019a3.193,3.193,0,1,0,6.376,0V397.306C349.745,392.478,347.1,389.108,341.911,389.108Z"
                    transform="translate(-19.66 -36.973)"
                    fill="#0041d3"
                />
                <path
                    id="Trazado_48"
                    data-name="Trazado 48"
                    d="M372.493,389.418a3.087,3.087,0,0,0-3.188,3.37v15.85a3.193,3.193,0,1,0,6.376,0v-15.85A3.111,3.111,0,0,0,372.493,389.418Z"
                    transform="translate(-33.662 -37.056)"
                    fill="#0041d3"
                />
                <path
                    id="Trazado_49"
                    data-name="Trazado 49"
                    d="M372.343,376.929a3.6,3.6,0,1,0,3.6,3.6A3.577,3.577,0,0,0,372.343,376.929Z"
                    transform="translate(-33.513 -33.722)"
                    fill="#0041d3"
                />
                <path
                    id="Trazado_50"
                    data-name="Trazado 50"
                    d="M394.174,382.851a2.526,2.526,0,0,0,2.778-2.642c0-1.5-1-2.6-2.914-2.6h-.455c-5.283,0-7.743,2.232-7.743,7.606v1.047c0,.183-.136.273-.319.273h-.182a2.375,2.375,0,1,0,0,4.737h.182c.183,0,.319.091.319.273v13.936a3.193,3.193,0,1,0,6.376,0V391.549a.242.242,0,0,1,.273-.273h1.731a2.375,2.375,0,1,0,0-4.737h-1.731a.241.241,0,0,1-.273-.273v-1.138C392.215,383.488,392.763,382.851,394.174,382.851Z"
                    transform="translate(-37.262 -33.904)"
                    fill="#0041d3"
                />
                <path
                    id="Trazado_51"
                    data-name="Trazado 51"
                    d="M422.108,389.418a2.958,2.958,0,0,0-2.915,2.232L415.276,402.9h-.091L410.95,391.65a3.117,3.117,0,0,0-6.1.774,3.731,3.731,0,0,0,.318,1.5l7.059,16.715-.591,1.64c-.548,1.593-1.321,2.186-2.688,2.231-1.867.045-2.961,1.138-2.961,2.733a2.687,2.687,0,0,0,2.961,2.687h.274c3.962,0,5.874-1.5,7.788-6.285l7.879-19.721a3.731,3.731,0,0,0,.318-1.5A2.969,2.969,0,0,0,422.108,389.418Z"
                    transform="translate(-43.152 -37.056)"
                    fill="#0041d3"
                />
                <g id="Grupo_17" data-name="Grupo 17" transform="translate(374.698 378.017)">
                    <path
                        id="Trazado_52"
                        data-name="Trazado 52"
                        d="M425.4,424.418a2.808,2.808,0,1,0,2.815,2.8A2.767,2.767,0,0,0,425.4,424.418Zm0,5.03a2.221,2.221,0,1,1,2.2-2.229A2.138,2.138,0,0,1,425.4,429.448Z"
                        transform="translate(-422.587 -424.418)"
                        fill="#0041d3"
                    />
                    <path
                        id="Trazado_53"
                        data-name="Trazado 53"
                        d="M427.367,427.1c0-.63-.425-1-1.173-1h-1a.377.377,0,0,0-.41.41v2.3a.393.393,0,0,0,.41.44.4.4,0,0,0,.425-.44v-.66c0-.044.015-.073.059-.073h.366l.484.88a.468.468,0,0,0,.426.293.366.366,0,0,0,.38-.367.383.383,0,0,0-.073-.249l-.382-.689A.865.865,0,0,0,427.367,427.1Zm-1.231.322h-.454c-.044,0-.059-.015-.059-.059v-.542a.054.054,0,0,1,.059-.059h.454c.279,0,.425.117.425.337S426.414,427.418,426.136,427.418Z"
                        transform="translate(-423.174 -424.867)"
                        fill="#0041d3"
                    />
                </g>
                <rect
                    id="Rectángulo_16"
                    data-name="Rectángulo 16"
                    width="42.27"
                    height="69.146"
                    rx="12"
                    transform="translate(383.542 315.515)"
                    fill="#0041d3"
                />
                <g id="Grupo_18" data-name="Grupo 18" transform="translate(389.528 332.457)">
                    <path
                        id="Trazado_54"
                        data-name="Trazado 54"
                        d="M446.567,374.752a3.088,3.088,0,0,0-3.189,3.37v15.849a3.193,3.193,0,1,0,6.377,0V378.122A3.112,3.112,0,0,0,446.567,374.752Z"
                        transform="translate(-442.968 -365.598)"
                        fill="#fff"
                    />
                    <path
                        id="Trazado_55"
                        data-name="Trazado 55"
                        d="M446.417,362.263a3.6,3.6,0,1,0,3.6,3.6A3.577,3.577,0,0,0,446.417,362.263Z"
                        transform="translate(-442.819 -362.263)"
                        fill="#fff"
                    />
                    <path
                        id="Trazado_56"
                        data-name="Trazado 56"
                        d="M476.54,362.946H458.824c-2.049,0-3.37,1.23-3.37,3.006,0,1.731,1.32,2.961,3.37,2.961h5.238a.241.241,0,0,1,.273.273v21.451a3.354,3.354,0,1,0,6.7,0V369.186a.241.241,0,0,1,.273-.273h5.237c2.049,0,3.37-1.23,3.37-2.961C479.91,364.176,478.59,362.946,476.54,362.946Z"
                        transform="translate(-446.193 -362.445)"
                        fill="#fff"
                    />
                </g>
                <g id="Grupo_20" data-name="Grupo 20" transform="translate(268.793 250.632)">
                    <path
                        id="Trazado_57"
                        data-name="Trazado 57"
                        d="M373.763,290.989l-42.554-38.713a6.325,6.325,0,0,0-8.506,0l-42.554,38.713a6.292,6.292,0,0,0-.4,8.884,6.4,6.4,0,0,0,4.665,2.049,6.263,6.263,0,0,0,4.265-1.649l.806-.734v31.776a3.25,3.25,0,0,0,1.991,2.771,6.616,6.616,0,0,0,2.986.659h64.986a6.616,6.616,0,0,0,2.986-.659,3.3,3.3,0,0,0,1.992-2.771V299.537l.808.736a6.3,6.3,0,0,0,8.53-9.284Z"
                        transform="translate(-278.104 -250.631)"
                        fill="#0041d3"
                    />
                    <g id="Grupo_19" data-name="Grupo 19" transform="translate(37.355 53.285)">
                        <path
                            id="Trazado_58"
                            data-name="Trazado 58"
                            d="M353.856,325.628l-12.816-2.283a1.227,1.227,0,0,0-1.442,1.207v31.5a1.227,1.227,0,0,0,1.335,1.223l12.816-1.134a1.226,1.226,0,0,0,1.119-1.222v-28.08A1.225,1.225,0,0,0,353.856,325.628Zm-10.9,16.351c-.528,0-.956-.75-.956-1.678s.428-1.678.956-1.678.956.753.956,1.678S343.485,341.979,342.957,341.979Z"
                            transform="translate(-331.878 -323.326)"
                            fill="#fff"
                        />
                        <path
                            id="Trazado_59"
                            data-name="Trazado 59"
                            d="M329.346,325.092a.958.958,0,0,0-.28.676v29.86a.957.957,0,0,0,.954.957h5.956v-1.912h-5V326.723h5v-1.912H330.02A.954.954,0,0,0,329.346,325.092Z"
                            transform="translate(-329.066 -323.722)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>*/
    );
};

export default Logo;
