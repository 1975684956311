// assets
import { IconHome2, IconUserPlus, IconUsers } from '@tabler/icons';

// constant
const icons = { IconHome2, IconUserPlus, IconUsers };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Inicio',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Mi Q-Home',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconHome2,
            breadcrumbs: false
        },
        {
            id: 'homify-admin',
            title: 'Mi Q-Home Admin',
            type: 'item',
            url: '/dashboard/homify-admin',
            icon: icons.IconHome2,
            breadcrumbs: false
        },
        {
            id: 'create',
            title: 'Crear Espacio',
            type: 'item',
            url: '/dashboard/create',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'create-user',
            title: 'Crear Usuario',
            type: 'item',
            url: '/dashboard/create-user',
            icon: icons.IconUserPlus,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
