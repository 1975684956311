import axios from 'axios';
import { getAccessToken, isAuthenticated, signOut } from '.';

const API_URL = 'https://api-devel.homifyit.com/com.homifyit.backend/api';

// const apiCall = axios.create();
const apiCall = axios.create({ baseURL: API_URL });

apiCall.interceptors.request.use(
    (config) => {
        const accessToken = getAccessToken();

        if (accessToken && isAuthenticated()) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

apiCall.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error);
        if (error.code == 'ERR_NETWORK') {
            return Promise.reject(error);
        }

        // Manejar errores de autorización
        if (error?.response?.status === 401) {
            signOut();
        }
        return Promise.reject(error);
    }
);

export default apiCall;
