import apiCall from './auth.interceptor';

// const API_URL = "https://api-devel.homifyit.com/com.homifyit.backend/api";
let _authenticated = false;

export const isAuthenticated = () => {
    return _authenticated;
};

/**
 *  Gestion del AccessToken
 */
export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};

export const setAccessToken = (token) => {
    return localStorage.setItem('accessToken', token);
};

/**
 *  Gestion del RefresToken
 */

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (token) => {
    return localStorage.setItem('refreshToken', token);
};

/**
 * Iniciar sesion
 *
 * @param {*} username
 * @param {*} password
 * @returns
 */
export const signIn = async (username, password) => {
    try {
        if (_authenticated) {
            return true;
        }

        const payload = {
            username: username,
            password: password
        };

        const response = await apiCall.post(`login`, payload);

        const { data } = response;

        // Se guarda el token en el localStorage
        if (data.access_token) {
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            _authenticated = true;
        }

        //TODO Guardar los datos del usuario como el correo

        return _authenticated;
    } catch (error) {
        console.error(error);
        return null;
    }
};

/**
 * Cerrar sesion
 *
 * @returns
 */
export const signOut = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    _authenticated = false;

    return true;
};

// export const ProtectedLayout = () => {
//     if (!check()) {
//         return <Navigate to="/" />;
//     }
// }

export const check = async () => {
    // Validamos si ya tiene la sesion iniciada

    let _return = false;

    // Sesion iniciada
    if (_authenticated) return true;

    // La sesion puede estar cerrada pero el token guardado
    if (getAccessToken()) {
        _return = true;
    }

    _authenticated = _return;

    console.log(_authenticated);

    return _return;
};
