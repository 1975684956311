// assets
import { IconBuildingCommunity, IconCalendarEvent, IconMessages, IconPhone, IconFile, IconCup } from '@tabler/icons';

// constant
const icons = {
    IconBuildingCommunity,
    IconCalendarEvent,
    IconMessages,
    IconPhone,
    IconFile,
    IconCup
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Comunidad',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Servicios',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Sala común',
                    type: 'item',
                    url: '/utils/util-typography',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'Gimnasio',
                    type: 'item',
                    url: '/utils/util-typography',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'util-calendar',
            title: 'Agenda',
            type: 'item',
            url: '/utils/util-calendar',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'icons',
            title: 'Comunicados',
            type: 'collapse',
            icon: icons.IconMessages,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Reuniones comunidad',
                    type: 'item',
                    url: '/icons/tabler-icons',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'Incidencias',
                    type: 'item',
                    url: '/icons/material-icons',
                    breadcrumbs: false
                },
                {
                    id: 'material-noticias',
                    title: 'Tablón de notcias',
                    type: 'item',
                    url: '/icons/material-icons',
                    breadcrumbs: false
                },
                {
                    id: 'material-consultas',
                    title: 'Solicitudes y consultas',
                    type: 'item',
                    url: '/icons/material-icons',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'util-tlf-interes',
            title: 'Teléfonos de interés',
            type: 'item',
            url: '/utils/util-important-phones',
            icon: icons.IconPhone,
            breadcrumbs: false
        },
        {
            id: 'util-svc-recomendados',
            title: 'Servicios recomendados',
            type: 'item',
            url: '/utils/util-recommended-services',
            icon: icons.IconCup,
            breadcrumbs: false
        },
        {
            id: 'util-documentos',
            title: 'Documentos',
            type: 'item',
            url: '/utils/util-shadow',
            icon: icons.IconFile,
            breadcrumbs: false
        }
    ]
};

export default utilities;
